<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('market.plan/add')"
      addName="新增充值优惠"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.plan_name"
          size="small"
          clearable
          placeholder="按优惠名称查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.plan_type"
          placeholder="按优惠类型查询"
          clearable
          size="small"
        >
          <el-option
            label="钱包"
            :value="10"
          >
          </el-option>
          <el-option
            label="电卡"
            :value="20"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="plan_name"
        title="优惠名称"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="plan_type"
        title="优惠类型"
        align="center"
      >
        <template v-slot="{ row }">
          <span>{{types.plan_type[row.plan_type]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="plan_money"
        title="充值金额（元）"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="gift_money"
        title="赠送金额（元）"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="160"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOne(row)"
            v-if="$hasAccess('market.plan/edit')"
          >编辑</el-button>
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增充值优惠"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="优惠名称"
          prop="plan_name"
        >
          <el-input
            v-model="addDialogFormData.plan_name"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="优惠类型"
          prop="plan_type"
        >
          <el-radio-group v-model="addDialogFormData.plan_type">
            <el-radio :label="10">钱包</el-radio>
            <el-radio :label="20">电卡</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="充值金额"
          prop="plan_money"
        >
          <el-input-number
            v-model="addDialogFormData.plan_money"
            controls-position="right"
            :min="0"
            :step="1"
            :precision="2"
            style="width: 80%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="赠送金额"
          prop="gift_money"
        >
          <el-input-number
            v-model="addDialogFormData.gift_money"
            controls-position="right"
            :min="0"
            :step="1"
            :precision="2"
            style="width: 80%;"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('market.plan/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑充值优惠"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="editDialogFormData"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="优惠名称"
          prop="plan_name"
        >
          <el-input
            v-model="editDialogFormData.plan_name"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="优惠类型"
          prop="plan_type"
        >
          <el-radio-group v-model="editDialogFormData.plan_type">
            <el-radio :label="10">钱包</el-radio>
            <el-radio :label="20">电卡</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="充值金额"
          prop="plan_money"
        >
          <el-input-number
            v-model="editDialogFormData.plan_money"
            controls-position="right"
            :min="0"
            :step="1"
            :precision="2"
            style="width: 80%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="赠送金额"
          prop="gift_money"
        >
          <el-input-number
            v-model="editDialogFormData.gift_money"
            controls-position="right"
            :min="0"
            :step="1"
            :precision="2"
            style="width: 80%;"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('market.plan/edit')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-plan-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '充值优惠',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      types: {
        plan_type: {
          10: '钱包',
          20: '电卡'
        }
      },
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      rules: {
        plan_name: [
          { required: true, message: '请输入优惠名称', trigger: 'change' }
        ],
        plan_type: [
          { required: true, message: '请选择优惠类型', trigger: 'change' }
        ],
        plan_money: [
          { required: true, message: '请输入充值金额', trigger: 'change' }
        ],
        gift_money: [
          { required: true, message: '请输入赠送金额', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['plan_id', 'plan_name', 'plan_money', 'plan_type', 'gift_money']
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return true
    }
  },
  mounted () {
  },
  activated () {
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Market.GetPlanList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {
        plan_type: 10
      }
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        plan_id: row.plan_id
      }
      const res = await this.$api.Market.GetPlanDetail(params)
      if (res) {
        const { plan_id, plan_name, plan_money, plan_type, gift_money } = res
        this.editDialogFormData = { plan_id, plan_name, plan_money, plan_type, gift_money }
        this.editDialogFormOriData = { plan_id, plan_name, plan_money, plan_type, gift_money }
      }
      this.editDialogShow = true
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除该优惠吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          plan_id: row.plan_id
        }
        await this.$api.Market.DeletePlan(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.Market.AddPlan(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Market.EditPlan(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
